import React, { Suspense } from "react";
import { withRouter, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
import LayoutAlternative from "./layouts/LayoutAlternative";
import LayoutSignin from "./layouts/LayoutSignin";

// Views

const Home = React.lazy(() => import("./views/Home"));
const Secondary = React.lazy(() => import("./views/Secondary"));
const Login = React.lazy(() => import("./views/Login"));
const Signup = React.lazy(() => import("./views/Signup"));
const TalentListings = React.lazy(() => import("./views/TalentListings"));
const Employer = React.lazy(() => import("./views/Employer"));
const ListingTemplate = React.lazy(() => import("./views/ListingTemplate"));
const PrivacyPolicy = React.lazy(() => import("./views/PrivacyPolicy"))
class App extends React.Component {
	componentDidMount() {
		document.body.classList.add("is-loaded");
		this.refs.scrollReveal.init();
	}

	// Route change
	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.refs.scrollReveal.init();
		}
	}

	render() {
		return (
			<Suspense fallback={<div>Loading...</div>}>
				<ScrollReveal
					ref="scrollReveal"
					children={() => (
						<Switch>
							<AppRoute
								exact
								path="/"
								component={Home}
								layout={LayoutDefault}
							/>
							<AppRoute
								exact
								path="/secondary"
								component={Secondary}
								layout={LayoutAlternative}
							/>
							<AppRoute
								exact
								path="/employers"
								component={Employer}
								layout={LayoutAlternative}
							/>
							<AppRoute
								exact
								path="/login"
								component={Login}
								layout={LayoutSignin}
							/>
							<AppRoute
								exact
								path="/signup"
								component={Signup}
								layout={LayoutSignin}
							/>
							<AppRoute
								exact
								path="/listings"
								component={TalentListings}
								layout={LayoutAlternative}
							/>
							<AppRoute
								exact
								path="/privacy-policy"
								component={PrivacyPolicy}
								layout={LayoutAlternative}
							/>
							<AppRoute
								exact
								path="/listings/0"
								component={() => (
									<ListingTemplate
										title="Senior Data Analyst"
										location="New York, NY"
										id="0"
										yearsExp="10+"
										description="We are seeking a highly capable data analyst to: \n
                  ·         enhance the accuracy and integrity of our organization's data. \n
                  ·         exhibit extensive knowledge of data analysis techniques like Data Quality, Master Data Management, Data Governance and experience in a similar role. \n
                  ·         turn data into information, information into insight and insight into business decisions.\n
                   \n
                  Responsibilities:\n
                  ·         Analyze master data and master relationship data within the data sets in the organization.  \n
                  ·         Developing and implementing data analyses, data collection systems and other strategies that optimize statistical efficiency and quality.\n
                  ·         Interact with data users, managers, and other stakeholder to report the data analysis findings and suggest improvements.\n
                  ·         Help translate analytics into non-technical insights to help all teams make well-informed decisions based on empirical evidence.\n
                  ·         Resolve data quality problems. Activities include: Identify, assess, fix, document, and communicate quality issues in the way data is collected, stored, processed, or used. This may involve interacting with business users to resolve data quality problems using techniques such as root cause analysis as well as collecting user requirements.\n
                  ·         Identify and describe the complex data quality issues and document for review with stakeholders.\n
                  ·         Creating appropriate documentation that allows stakeholders to understand the steps of the data analysis process and duplicate or replicate the analysis if necessary.\n
                  ·         Serve as a liaison between Business and Technical teams.\n
                  ·         Keep abreast of developments and trends in data analysis.\n
                   \n
                  Skill Requirements:\n
                      Experience with Master Data Management or Data Quality.\n
                      Experience with Data Modeling will be a plus.\n
                  ·         Knowledge of P&C Insurance will be a plus .\n
                  ·         Exceptional analytical skills.\n
                  ·         Advanced problem-solving skills.\n
                  ·         Knowledge of best practices in data analysis.\n
                  ·         Ability to review, analyze and manage large volumes of data\n
                  ·         Outstanding attention to detail and a meticulous approach to work.\n
                  ·         Experience in root cause analysis and implementation of quality management principles\n
                  ·         Experience in DQ Measurements, Data Entry Improvement, User Requirements.\n
                  ·         Good problem solving (error detection, root cause analysis, error correction), \n
                  ·         Strong verbal and written communication skills to interact with business and technical teams."
									/>
								)}
								layout={LayoutAlternative}
							/>
							<AppRoute
								exact
								path="/listings/1"
								component={() => (
									<ListingTemplate
										title="Data Analyst"
										location="New York, NY"
										id="1"
										yearsExp="5+"
										description="We are seeking a highly capable data analyst to: \n
                  ·         enhance the accuracy and integrity of our organization's data. \n
                  ·         exhibit extensive knowledge of data analysis techniques like Data Quality, Master Data Management, Data Governance and experience in a similar role. \n
                  ·         turn data into information, information into insight and insight into business decisions.\n
                   \n
                  Responsibilities:\n
                  ·         Analyze master data and master relationship data within the data sets in the organization.  \n
                  ·         Developing and implementing data analyses, data collection systems and other strategies that optimize statistical efficiency and quality.\n
                  ·         Interact with data users, managers, and other stakeholder to report the data analysis findings and suggest improvements.\n
                  ·         Help translate analytics into non-technical insights to help all teams make well-informed decisions based on empirical evidence.\n
                  ·         Resolve data quality problems. Activities include: Identify, assess, fix, document, and communicate quality issues in the way data is collected, stored, processed, or used. This may involve interacting with business users to resolve data quality problems using techniques such as root cause analysis as well as collecting user requirements.\n
                  ·         Identify and describe the complex data quality issues and document for review with stakeholders.\n
                  ·         Creating appropriate documentation that allows stakeholders to understand the steps of the data analysis process and duplicate or replicate the analysis if necessary.\n
                  ·         Serve as a liaison between Business and Technical teams.\n
                  ·         Keep abreast of developments and trends in data analysis.\n
                   \n
                  Skill Requirements:\n
                      Experience with Master Data Management or Data Quality.\n
                      Experience with Data Modeling will be a plus.\n
                  ·         Knowledge of P&C Insurance will be a plus .\n
                  ·         Exceptional analytical skills.\n
                  ·         Advanced problem-solving skills.\n
                  ·         Knowledge of best practices in data analysis.\n
                  ·         Ability to review, analyze and manage large volumes of data\n
                  ·         Outstanding attention to detail and a meticulous approach to work.\n
                  ·         Experience in root cause analysis and implementation of quality management principles\n
                  ·         Experience in DQ Measurements, Data Entry Improvement, User Requirements.\n
                  ·         Good problem solving (error detection, root cause analysis, error correction), \n
                  ·         Strong verbal and written communication skills to interact with business and technical teams."
									/>
								)}
								layout={LayoutAlternative}
							/>
							<AppRoute
								exact
								path="/listings/2"
								component={() => (
									<ListingTemplate
										title="Sr. Business Insurance Analyst"
										location="New York, NY"
										yearsExp="10+"
										id="2"
										description="We are seeking a highly capable Insurance Business analyst to\n
              ·         engage in client discussions and capture requirements/ specs based on interaction with business users.\n
              ·         document requirements and ensure solutions meet business needs and requirements.\n
              ·         work very closely with business units and the technical development teams on all projects.\n\n
              Responsibilities:\n
              ·         Participate in Requirements definition sessions to evaluate business processes, anticipate requirements, uncovering areas for improvement, and developing and implementing solutions.\n
              ·         Conduct meetings and presentations to elicit requirements and review feedback with stakeholders. \n
              ·         Perform requirements analysis and documenting and communicating the results.\n
              ·         Create Business Requirements and Data Requirements to ensure the solutions meet business needs and requirements.\n
              ·         Serve as a liaison between Business and Technical teams.\n
              ·         Manage competing resources and priorities.\n
              ·         Monitor deliverables and ensure timely completion of projects.\n\n
              Requirements:\n
              ·         10+ years’ experience in business analysis for P&C Insurance.\n
              ·         Knowledge of various personal and commercial lines of P&C insurance products such as - auto, property, General liability, workers compensation and other miscellaneous P&C products in the current market, and their business processes and compliance rules in various markets.\n
              ·         Working knowledge of Insurance systems and processes.\n
              ·         Ability to gather, analyze and document insurance data requirements.\n
              ·         Ability to understand and translate user needs into business specifications.\n
              ·         The ability to influence stakeholders and work closely with them to determine acceptable solutions.\n
              ·         Excellent interpersonal skills with the ability to establish working relationships with individuals at varying levels within the organization.\n
              ·         Strong verbal and written communication skills to interact with business and technical teams.\n
              ·         Experience in Data projects and Data Modeling will be a plus."
									/>
								)}
								layout={LayoutAlternative}
							/>
						</Switch>
					)}
				/>
			</Suspense>
		);
	}
}

export default withRouter((props) => <App {...props} />);
