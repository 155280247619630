import React from "react";
import classNames from "classnames";

const FooterSocial = ({ className, ...props }) => {
	const classes = classNames("footer-social", className);

	return (
		<div {...props} className={classes}>
			<ul className="list-reset">
				<li>
					<a href="https://www.linkedin.com/company/dendrite-it">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<title>LinkedIn</title>
							<path d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z" />
						</svg>
					</a>
				</li>
			</ul>
		</div>
	);
};

export default FooterSocial;
